<template >
<MyHeader id="home"></MyHeader>
<section class="home" >
  <div class="container_home">
    <div class="hero_banner_left">
      <div class="square">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 309.135 300.545">
          <g id="Tracé_3" data-name="Tracé 3" fill="none">
            <path d="M0,0H309.135V300.545H0Z" stroke="none"/>
            <path d="M 10 10 L 10 290.5447998046875 L 299.1345825195312 290.5447998046875 L 299.1345825195312 10 L 10 10 M 0 0 L 309.1345825195312 0 L 309.1345825195312 300.5448303222656 L 0 300.5448303222656 L 0 0 Z" stroke="none" fill="#f8611d"/>
          </g>
        </svg>
      </div>
    </div>
    <div class="hero_banner_right">
      <h1 class="banner_title">Hello ! My name is <span class="h1Name">Carlos</span>, and I am a Front-End Developer</h1>
      <p class="banner_txt">
     At the moment I am working in a web development agency after I finished my degree at Digital Campus Bordeaux in September. Here, I want to share with you my projects so that you can find out more about me and hopefully we can work together in the future
      </p>
    </div>
  </div>
 
</section>
<my-projects id="projects"></my-projects>
<About id="about"></About>
<my-contact></my-contact>
<div class="call-to-scrool">
    <span class="scroll-txt">Scroll</span>
    <span class="line"></span>
</div>
<my-footer>

</my-footer>
</template>

<script>

import MyHeader from '@/components/MyHeader.vue';
import About from '@/components/About.vue';
import MyProjects from '@/components/MyProjects.vue';
import MyContact from '@/components/MyContact.vue';
import MyFooter from '@/components/MyFooter.vue';


export default {
  name: 'App',
  components: {
    MyHeader,
    About,
    MyProjects,
    MyContact,
    MyFooter
  }, data(){
    return {
      color:'#F8611D'
    }
  }
}
</script>

<style>
@import url("https://use.typekit.net/avn2eeh.css");
/* desktop
:root{
    --futura-font:'futura-pt', sans-serif;
    --agenda-font:'agenda', sans-serif;
    --box-shadow: 2px 2px 10px rgba(0,0,0,.3);
    --bgColor:#fff;
    --bgColorWhiteGrey:#F3F3F3;
    --clrDarkGrey:#4B4B4B;
    --clrOrange:#F8611D;
    --fwBook: 400;
    --fwMedium: 500;
    --fwDemi: 600;
    --fwExtBold: 800;
    --fs800: 70px;
    --fs700: 42px;
    --fs600: 25px;
    --fs500: 22px;
    --fs400: 18px;
  } */
  html {
  scroll-behavior: smooth;
  }

  :root{
    --futura-font:'futura-pt', sans-serif;
    --agenda-font:'agenda', sans-serif;
    --box-shadow: 2px 2px 10px rgba(0,0,0,.3);
    --bgColor:#fff;
    --bgColorWhiteGrey:#F3F3F3;
    --clrDarkGrey:#4B4B4B;
    --clrOrange:#F8611D;
    --fwBook: 400;
    --fwMedium: 500;
    --fwDemi: 600;
    --fwExtBold: 800;
    --fs900: 50px;
    --fs800: 45px;
    --fs700: 30px;
    --fs600: 25px;
    --fs500: 16px;
    --fs400: 15px;
    --scale:.6;
  }
  *,::before,::after{
    margin: 0;
    padding: 0;
    list-style:none;
    text-decoration: none;
    box-sizing: border-box;
  }
  body {
    background: var(--bgColorWhiteGrey);
    font-family: var(--futura-font);
    color: var(--clrDarkGrey);
  }
  .home{
    margin-top: 30px;
    min-height: 93vh;
    display: flex;
    align-items: center;
    padding: 0 36px;
  }
  .container_home {
    max-width: 1100px;
    margin: 0 auto;

    display: flex;
    width: 100%;
    align-items:center;
    flex-wrap: wrap;
    justify-content: center;
  
  }
 
  .call-to-scrool {
  display: none;
   
  }
  .hero_banner_left{
    min-width: 356px;
  }
  .square {
    position: relative;
    width: 309px;
    height: 309px;
    scale: var(--scale);
  }
  .square::before{
    content:'';
    width: 309px;
    height: 309px;
    display: block;
    scale: .9;
    top:-80px;
    left: 80px;
    position: absolute;
    background-image: url('@/assets/img/picture_profile_carlos1.JPG');
    background-size:cover;

  }
  .hero_banner_right {
    max-width: 627px;
  }
  .hero_banner_right h1 {
    font-size: var(--fs700);
    font-family: var(--agenda-font);
    font-weight: var(--fwMedium);
  }
  .h1Name {
    color: var(--clrOrange);
  }
  .hero_banner_right p {
    font-size: var(--fs500);
    margin: 53px 0;


  }

  @media screen and (min-width:800px) {
    :root{
      --scale:.8;
    }
  }
  @media screen and (min-width:1080px) {
    :root{
    --futura-font:'futura-pt', sans-serif;
    --agenda-font:'agenda', sans-serif;
    --box-shadow: 2px 2px 10px rgba(0,0,0,.3);
    --bgColor:#fff;
    --bgColorWhiteGrey:#F3F3F3;
    --clrDarkGrey:#4B4B4B;
    --clrOrange:#F8611D;
    --fwBook: 400;
    --fwMedium: 500;
    --fwDemi: 600;
    --fwExtBold: 800;
    --fs900:70px;
    --fs800: 80px;
    --fs700: 40px;
    --fs600: 25px;
    --fs500: 21px;
    --fs400: 18px;
    --scale:.9;

  }
  .container_home {
    justify-content: space-between;
    gap: 30px;

  }
  
  .call-to-scrool {
  display: flex;
  align-items: center;
  gap: 5px;
  position: fixed;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  writing-mode: vertical-rl;

   
}
.call-to-scrool span:first-child {
font-size: 25px;
}
@keyframes  scroolLine {

  0%{
    transform: scaleY(0);
    transform-origin: top;

  }
  50%{
    transform: scaleY(1);
    transform-origin: top;

  }
  100%{
     transform: scaleY(0);
    transform-origin: bottom;

  }
  }
.call-to-scrool::before{
  position: absolute;
  content: "";
  width: 10%;
  height: 100%;
  background: var(--clrOrange);
  top: 100%;
  border-radius: 3px;
  transform-origin: bottom;
  transform: scaleY(0);
  animation-duration: 1.7s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scroolLine;

    }
   
}

@media screen and (min-width:1280px) {
  :root{
    --scale:1.1;
  }
}
</style>
