<template>
    <a href="#top" class="pulse-button">
        <img id="arrow_up" src="@/assets/img/awesome-arrow-up.svg" alt="icone-scroll">
    </a>
    <footer>
        <div class="footer_item_left">
            <div class="logo logo_footer">
                <img src="@/assets/logo.svg" alt="logo-portfolio-carlos-vieira-nkulu">
            </div>

        </div>
        <div class="footer_item_right">
            <ul class="socialLinks">
                <li><a class="social" target="blank" href="https://www.instagram.com/carlos.vieira.pro/"><img src="@/assets/img/social-instagram.svg" alt="icone-instagram"></a></li>
                <li><a class="social" target="blank" href="https://www.linkedin.com/in/carlos-vieira-nkulu-8950b4200/"><img src="@/assets/img/social-linkedin.svg" alt="icone-linkedin"></a></li>
                <li><a class="social" target="blank" href="https://github.com/carlos-v278"><img src="@/assets/img/social-github.svg" alt="icone-github"></a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'MyFooter',
  data(){
    return{

    }
  },
  props: {
  },methods:{
 
  }
}
</script>


<style >
footer {
    padding: 20px;
    max-width: 1280px;
    margin: 0 auto;
    min-height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo_footer {
    padding: 0;
    scale: 1.2;
}
.socialLinks{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.social {
    display: block;
    width: 21px;
    height: 21px;
    transition: transform .2s ease-out;
}
.social:hover {
    transform: scale(1.2);
}
.social img {
    width: 100%;
    height: 100%;
}
.pulse-button {
  z-index: 1;
  color: green;
  right: 30px;
  bottom: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: 0 0 0 0 var(--clrOrange);
  border-radius: 50%;
  background-color: var(--clrOrange);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}
#arrow_up {
  display: flex;
  justify-content: center;
  transform: scale(0.7);
}
.formation_item_title {
  display: none;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 25px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 25px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 25px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 25px rgba(232, 76, 61, 0);
  }
}
@media screen and (min-width:881px) {

}
</style>
