<template>
    <header id="top" class="header">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="logo-portfolio-carlos-vieira-nkulu">
      </div>
      <div class="navlinks_containerM" :class="{ navlinkActive: toggleNav }">
        <ul class="navlinks">
          <li class="navlink"><a @click="disableBurger()" href="#home">Home</a></li>
          <li class="navlink"><a @click="disableBurger()" href="#projects">Projects</a></li>
          <li class="navlink"><a @click="disableBurger()" href="#about">About</a></li>
          <li class="navlink"><a @click="disableBurger()" href="#contact">Contact</a></li>
        </ul>
      </div>
      <ul class="navlinksDesktop">
          <li class="navlink"><a href="#home">Home</a></li>
          <li class="navlink"><a href="#projects">Projects</a></li>
          <li class="navlink"><a href="#about">About</a></li>
          <li class="navlink"><a href="#contact">Contact</a></li>
      </ul>
      
      <div class="burger" @click="burgerActive()" :class="{ toggleBurger: toggleNav}">
        <div class="div1"></div>
        <div class="div2"></div>
        <div class="div3"></div>
      </div>


    </header>
</template>

<script>
export default {
  name: 'MyHeader',
  data(){
    return{
      toggleNav:false,
    }
  },
  props: {
  },methods:{
    burgerActive(){
       let html = document.querySelector('body')
       let body = document.querySelector('html')
      if(this.toggleNav){
        this.toggleNav = false
        body.classList.remove("overflowBody")
        html.classList.remove("overflowBody")
      }else {
        this.toggleNav = true
        body.classList.toggle("overflowBody")
        html.classList.toggle("overflowBody")
      }
    },disableBurger(){
      this.toggleNav= false
    }
  }
}
</script>


<style >
  .logo {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    box-sizing: content-box;
    padding-left: 40px;
    max-width: 47.5px;
    height:46.5px;
  }
  .logo img {
    width: 100%;
    height: 100%;
  }
header {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.burger {
  width: 40px;
  height: 23px;
  display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
cursor: pointer;
z-index: 1;
}
.burger div {
  background: var(--clrDarkGrey);
  height: 4px;
  width: 70%;
  border-radius: 5px;
  transition: transform .2s ease-in, width .2s ease;
}
.burger .div2 {
  transform-origin: center;
  width: 100%;
  opacity: 1;
}
.navlinks_containerM {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: white;
  top: 0;
  left: 0;
  transition: transform .2s ease-in;
  transform: translateX(-100%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}
.navlinksDesktop {
  display: none;
}
.navlinks {

  width: 100%;
  height:200px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.toggleBurger .div1 {
  transform: rotate(-48deg) translate(-6px, 7px) scale(1);
width: 100%;

}
.toggleBurger .div2 {
transform: scaleX(0);
}

.toggleBurger .div3 {
  transform: rotate(48deg) translate(-6px, -8px) scale(1);
  width: 100%;
}
.navlinkActive {
  transform: translateX(0%);

}

.navlinks a {

  color: var(--clrDarkGrey);
  font-size: 25px;

}
.navlink a::after {
  content: '';
  display: block;

  background-color: var(--clrOrange);
  width: 100%;
  z-index: 2;
  height: 3px;
  border-radius: 5px;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .2s ease-in-out;
  
}
.navlink a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
@media screen and (min-width:881px) {
  .burger {
    display: none;
  }
  .navlinks_containerM {
    display: none;
  }
  .navlinksDesktop {
    display: block;
    display: flex;
    align-items: center;
    gap: 47px;
    margin-right:130px;
  }
  .navlinksDesktop a{

    color: var(--clrDarkGrey);
    font-size: 25px;
    font-weight: 400;
  }
  .logo {
    margin: 0;
  }
}
</style>
