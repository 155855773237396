<template>

    <section class="section2 section">
      <div class="section_container">
        <div class="header_section">
          <h2>Projects</h2>
          <div class="Header_section_line"></div>
        </div>
        <div class="projects_items">

            <div class="project_item" v-for="projectItem in projects" :key="projectItem">
                <span class="project_title">{{projectItem.title}}</span>
                  <a  class="project_item_mockup" target="blank" :href="projectItem.url">
                    <div class="mockup_img">
                      <img :src="require(`@/assets/img/projects/${projectItem.img}.png`)" :alt="'mockup-'+projectItem">
                    </div>
                  </a>
            </div>
     
        </div>
      </div>

      
    </section>
</template>

<script>

export default {
  name: 'MyProjects',
  components:{
  },data(){
    return{
      projects:[
      {
          title:"CLF Formation",
          img:"clf_formation",
          url:"https://clf-formation.fr/"
        }, {
          title:"Meta Records",
          img:"metarecords",
          url:"https://metarecords.fr/"
        },{
          title:"Fredgie Attitude",
          img:"fredgieattitude",
          url:"http://fredgieattitude.fr/"
        },
        
      ]
    }
  },

  props: {
  },methods:{
 
  }
}
</script>


<style scoped>
  .section {
    padding: 80px 34px;
    min-height: 100vh;
    background: white;
  }

  .projects_items {

      margin: 130px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 70px;
  }
  .project_item {
      max-width: 304px;
      cursor: pointer;
      
  }
  .project_title {
      width: 100%;
      display: block;
      font-size: var(--fs600);
      padding-bottom: 5px;
      border-bottom: 2px solid var(--clrDarkGrey);
  }
  .project_title span{
    font-weight: var(--fwDemi);
  }
  .project_item_mockup {
    display: block;
    position: relative;
    margin: 36px 0 0;
      
  }
.mockup_img {
    width: 100%;
    max-height: 187px;
}
.mockup_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mockup_img::after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    background: url('@/assets/img/projects/mockup_hover.svg');
    width: 100%;
    opacity: 0;
    height: 100%;
    scale: 1;
    transition: opacity .2s ease, scale .2s ease;

}
.mockup_img:hover::after{
  opacity: 1;
  scale: .9;

}

@media screen and (min-width:881px) {
  .projects_items {
    flex-direction: row;
    gap: 30px;
    
  }
}



</style>
