<template>
    <section class="section1 section">
      <div class="section_container">
        <div class="header_section">
          <h2>About</h2>
          <div class="Header_section_line"></div>
        </div>
        <skills-items skillTitle="Soft skills" :skillsItems="softSkills"></skills-items>
        <skills-items skillTitle="Hard skills" :skillsItems="hardSkills"></skills-items>
        <div class="about_footer">
          <my-listing listTitle="Langages" :lstItems="languages"></my-listing>
          <my-listing listTitle="Applications" :lstItems="apps"></my-listing>
          <my-listing listTitle="Loisirs" :lstItems="hobby"></my-listing>
        </div>
        
      </div>
      
    </section>
</template>

<script>
import SkillsItems from '@/components/SkillsItems.vue';
import MyListing from '@/components/MyListing.vue';
export default {
  name: 'About',
  components:{
    SkillsItems,
    MyListing,
  },
  data(){
    return{
      softSkills:[
      {
          title:"Teamwork",
          img:"soft_skill1"
      },
      {
          title:"Curiosity",
          img:"soft_skill2"
      },
      {
          title:"Punctuality",
          img:"soft_skill3"
      },
      {
          title:"Pragmatism",
          img:"soft_skill4"
      }
      ],
      hardSkills:[
      {
          title:"UX/UI Design",
          img:"hard_skill1"
      },
      {
          title:"Front-end",
          img:"hard_skill2"
      },
      {
          title:"Back-end",
          img:"hard_skill3"
      }
      ],
      languages:[
        {
          title:'sass',
          img:'sass'
        },
        {
          title:'Symfony',
          img:'symfony'
        },
        {
          title:'Wordpress',
          img:'wordpress'
        },
        {
          title:'Php',
          img:'php'
        },
        {
          title:'Css',
          img:'css'
        },
        {
          title:'Html',
          img:'html'
        },
        {
          title:'Vue Js',
          img:'vue_js'
        },
           {
          title:'docker',
          img:'docker'
        },
           {
          title:'Node js',
          img:'node-js'
        },
           {
          title:'Typescript',
          img:'typescript'
        },
      ],
      apps:[
        {
          title:'InDesign',
          img:'indesign'
        },
        {
          title:'Illustrator',
          img:'illustrator'
        },
        {
          title:'Adobe-XD',
          img:'xd'
        },
        {
          title:'visual-studio-code',
          img:'vscode'
        },
      ],
      hobby:[
        {
          title:'Vélo',
          img:'velo'
        },
        {
          title:'Cinéma',
          img:'cinema'
        },
        {
          title:'musique',
          img:'musique'
        },
        {
          title:'cuisine',
          img:'cuisine'
        },
      ],
    }
  },
  props: {
  },methods:{
 
  }
}
</script>


<style >
  .section {
    padding: 80px 34px;
  min-height: 100vh;
  }
.section1 {

}
.section_container {
  max-width: 1088px;
  margin: 0 auto;
}
.header_section {
  display: flex;
  align-items: center;
  gap:30px;
}
.header_section h2 {
  font-size: var(--fs800);
  text-transform: uppercase;
  font-family: var(--agenda-font);
  min-width: 205px;
}
.Header_section_line {
  background: var(--clrOrange);
  height: 3px;
  max-width: 150px;
  width: 100%;
}
.about_footer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media screen and (min-width:781px) {

  .about_footer{
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
  }
}
@media screen and (min-width:1080px) {
  .about_footer{
    justify-content: space-between;
  }
}
</style>
