<template>
    <div class="skills_container">
        <h3>{{skillTitle}}
            <span class="cursor_svg">
                <svg id="hover-click-icon" xmlns="http://www.w3.org/2000/svg" width="21.461" height="25.164" viewBox="0 0 21.461 25.164">
                    <path id="Tracé_170" data-name="Tracé 170" d="M8.175,0a8.182,8.182,0,0,1,7.864,10.417L15.281,9.9a7.36,7.36,0,0,0,.2-1.724,7.309,7.309,0,1,0-8.834,7.151l.07.893A8.174,8.174,0,0,1,8.175,0Zm8.584,25.086a.848.848,0,0,1-.565.051.791.791,0,0,1-.483-.365l-2.029-3.5-1.929,2.165a2.434,2.434,0,0,1-.868.647,1.118,1.118,0,0,1-.659.076.884.884,0,0,1-.573-.41,1.6,1.6,0,0,1-.215-.735L8.361,9.006a.166.166,0,0,1,0-.031.556.556,0,0,1,.051-.252A.568.568,0,0,1,8.6,8.492a.313.313,0,0,1,.115-.055.576.576,0,0,1,.467.045.331.331,0,0,1,.068.041L20.82,16.448a1.6,1.6,0,0,1,.528.553.868.868,0,0,1,.068.7,1.133,1.133,0,0,1-.4.532,2.428,2.428,0,0,1-1,.428.035.035,0,0,1-.014,0l-2.826.586L19.2,22.757a.787.787,0,0,1,.074.6.841.841,0,0,1-.352.485.085.085,0,0,1-.023.014l-2.091,1.208c-.018.008-.033.016-.049.023Zm-.379-.659a.084.084,0,0,0,.059,0,.076.076,0,0,1,.023-.01l2.064-1.192a.13.13,0,0,0,.039-.055.065.065,0,0,0,0-.045L16.2,19.077h0a.384.384,0,0,1-.041-.109.366.366,0,0,1,.285-.432l3.322-.59a.03.03,0,0,1,.014,0,1.717,1.717,0,0,0,.692-.289.424.424,0,0,0,.152-.184.172.172,0,0,0-.02-.133.925.925,0,0,0-.293-.289L9.217,9.424l1.048,13.393a.9.9,0,0,0,.1.4.18.18,0,0,0,.1.084.422.422,0,0,0,.236-.039A1.7,1.7,0,0,0,11.3,22.8l2.07-2.494h0a.394.394,0,0,1,.09-.074.365.365,0,0,1,.5.133L16.344,24.4a.06.06,0,0,0,.037.025Zm.082-.012a.374.374,0,0,1,.16-.037l-.16.037ZM8.175,5.6A2.563,2.563,0,0,1,10,6.354c.047.047.094.1.137.149l-.074-.025a2.672,2.672,0,0,0-.428-.1c-.047-.008-.1-.016-.145-.02a2.976,2.976,0,0,0-.661,0l-.094.012a1.9,1.9,0,0,0-1.894.467l0,0a1.883,1.883,0,0,0-.551,1.335,1.869,1.869,0,0,1-.033.551,2.636,2.636,0,0,0-.049.467,2.5,2.5,0,0,0,0,.35l.025.324a2.574,2.574,0,0,1,.125-3.51l0,0A2.559,2.559,0,0,1,8.175,5.6Zm0-2.8a5.37,5.37,0,0,1,5.371,5.371c0,.176-.008.348-.025.52l-.788-.541a4.557,4.557,0,1,0-6.309,4.231l.07.895A5.374,5.374,0,0,1,8.175,2.8Z"/>
                </svg>
            </span>
        </h3>
        <div class="skills_items" >
            <div class="skill_item" v-for=" skillsItem in skillsItems" :key="skillsItem">
                <img :src="require(`@/assets/img/skills/${skillsItem.img}.png`)" :alt="'image-'+ skillsItem.title">
                <div class="skill_item_info">
                    <span>{{skillsItem.title}}</span>
                </div>
            </div>
         
           
        </div>

    </div>
</template>

<script>
export default {
  name: 'SkillsItems',
  data(){
    return{
    }
  },
  props: {
    skillTitle:String,
    skillsItems:Array,


  },methods:{
 
  }
}
</script>


<style >
    .skills_container{
        margin: 103px 0;
    }
    .skills_container h3{
        position: relative;
        display: flex;
        font-size: var(--fs600);
        font-weight: var(--fwBook);
        width: 100%;
        justify-content: space-between;
        margin-bottom: 45px;
    }
    .skills_container h3 span {
        display: none;
    }
    .skills_container h3::after{
        content: '';
        position: absolute;
        width: 100%;
        background: var(--clrDarkGrey);
        height: 2px;
        left: 0;
        bottom: -5px;

    }

    .skills_items{
        gap: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .skill_item{
        
        margin: 30px 0;
        cursor: pointer;
        position: relative;
        max-width: 200px;
        width: 100%;
        max-height: 200px;
    }
    .skill_item img{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
    .skill_item_info{
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        color: var(--clrDarkGrey);
        background: rgba(255, 255, 255, .8);
        height: 100%;
        width: 100%;
        opacity: 1;
        transform: scale(.8);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity .2s ease, transform .2s ease;
    }
    .skill_item_info span {
        font-size: 25px;
        width: fit-content;
    }
   

@media screen and (min-width:781px) {
    .skills_container h3 span {
        display: inline-block;
    }
    .skills_items{
        justify-content: flex-start;
    }
    .skills_container h3::after{
        width: 30%;
    }
}
@media screen and (min-width:1080px) {
    .skill_item_info{
        opacity: 0;
        transform: scale(1.2);
    }
    .skill_item_info:hover {
        opacity: 1;
        transform: scale(.8);
    }
}
</style>
