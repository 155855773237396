<template>
    <div class="listing_container">
     <span class="title_listing">{{listTitle}}</span> 
        <div class="listing_items">
            <div class="listing_item" v-for="item in lstItems" :key="item">
                <img :src="require(`@/assets/img/lists/${item.img}.svg`)" :alt="'icone-'+ item.title">
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
  name: 'MyListing',
  data(){
    return{

    }
  },
  props: {
    listTitle:String,
    lstItems:Array


  },methods:{
 
  }
}
</script>


<style >
    .listing_container{
        margin: 30px 0;
    }
    .title_listing{
        position: relative;
        font-size:var(--fs600) ;
        display: block;
        margin: 40px 0;
        font-weight: 400;
    }
    .title_listing::after{
        content: "";
        width: 100%;
        position: absolute;
        height: 2px;
        background: var(--clrDarkGrey);
        display: block;
        bottom: -5px;


    }
    .listing_items{
        display: flex;
        width: 307px;
        gap:38px ;
        align-items: center;
        flex-wrap: wrap;
    }
    .listing_item{
        display: block;
        width: 35px;

    }
    .listing_item img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
 


@media screen and (min-width:681px) {

}
</style>
