<template>
    <section class="section3 section" id="contact">
        <div class="section_container">
            <div class="header_section">
                <h2>Contact</h2>
                <div class="Header_section_line"></div>
            </div>
            <div class="main_contact">
              <div class="main_left">
                <form ref="form" @submit.prevent="sendEmail">
                  <div class="form_items">
                    <div class="form_item">
                      <label for="firstName">Last name*</label>
                      <input type="text" @focusin="inputFocusIn()" @focusout="inputFocusOut()" required name="user_Fname" id="firstName">
                    </div>
                    <div class="form_item">
                      <label  for="lastName">First name*</label>
                      <input type="text" @focusin="inputFocusIn()" @focusout="inputFocusOut()"    required name="user_Lname" id="lastName">
                    </div>

                    <div class="form_item">
                      <label  for="number">Phone*</label>
                      <input type="tel" @focusin="inputFocusIn()" @focusout="inputFocusOut()" required name="user_number" id="number">
                    </div>

                    <div class="form_item">
                      <label   for="mail">Email*</label>
                      <input type="email" @focusin="inputFocusIn()" @focusout="inputFocusOut()"   required name="user_email" id="mail">
                    </div>
                    <div class="form_item">
                      <select  required name="object" id="object" >
                        <option value="Projet">Project</option>
                        <option value="Emploi" >Job</option>
                        <option value="Renseignement" selected>Informations</option>
                      </select>
                    </div>

                    <div class="form_item">
                      <label  for="message">Message</label>
                      <textarea @focusin="inputFocusIn()" @focusout="inputFocusOut()" required  name="message" id="message" height="65px"></textarea>
                    </div>

                    <div class="form_item">
                      <div class="form_input">
                        <input type="submit" class="submit" value="Send" @click="submitAnime()">
                      </div>
                     
                    </div>
                  </div>
         
                  
                </form>

              </div>
              <div class="main_right">
                <h3>
                  Let's talk !
                </h3>
                <div class="contact_info">
                  <div class="info_item">
                    <span class="number_title">Phone: </span>
                    <a target="blank" href="tel:+33768827275" class="number_phone">+33 7 68 82 72 75</a>
                  </div>
                  <div class="info_item">
                    <span class="adress_title">Location: </span>
                    <a target="blank" href="https://goo.gl/maps/AFwR7gxip9mnbJP99" class="localisation">Saint-Pierre, 33000 Bordeaux</a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      
    </section>
</template>

<script>

import emailjs from '@emailjs/browser';


export default {
  name:"MyContact",
  data(){
      return{
        mailStatus:false,
        focusActive:false,
        submit:''
      }
  },
  methods: {
    sendEmail() {
      this.submit.parentElement.classList.add('animeSubmit')
      if(!this.mailStatus) {
        emailjs.sendForm('service_up7phbu', 'template_ofkoi5w', this.$refs.form, 'user_DcWX83qe8vaS0Q7WRifVi')
        .then((result) => {
            console.log('SUCCESS!', result.text);
            this.mailStatus = true
            alert('Le formulaire à bien était envoyé')

        }, (error) => {
            console.log('FAILED...', error.text);
        });
      } else{
        alert("Vous avez déja pris contact, les spams  n'est pas toléré. ")
      }
 
    },
    inputFocusIn(){
      let input = event.target
      input.parentElement.querySelector('label').classList.add('inputFocus')
    },
    inputFocusOut(){
      let input = event.target
      if(input.value == ""){
        input.parentElement.querySelector('label').classList.remove('inputFocus')
      } 
    },
    submitAnime(){
      this.submit = event.target
    
    }
  }
}
</script>


<style >
  .section {
    padding: 80px 34px;
    min-height: 100vh;
  }
.section3 {
    background: var(--bgColor);
}
.section_container {
  max-width: 1088px;
  margin: 0 auto;
}
.main_contact{
  display: flex;
flex-direction: column;
align-items: center;
  margin: 70px 0;

}
.main_left {
  width: 100%;
  max-width: 450px;
}
.form_items{max-width: 450px;}
.form_item{
  position: relative;
  margin: 45px 0;

}
.form_item label {
  font-size: var(--fs500);
  position: absolute;
  transition: transform .1s ease-in;
  top: -3px;
}
.form_item select{
  font-size: var(--fs500);
  font-family: var(--futura-font);
  color: var(--clrDarkGrey);
}

.form_item input, .form_item select, .form_item textarea{
  border: none;
  border-bottom: 2px solid var(--clrDarkGrey);
  height: 27px;
  width: 100%;

}
.inputFocus{
  
    transform: translate(-8px, -26px) scale(.8);

}
textarea{
  height: 65px !important;
}
.form_item select{
  height: 34px;
}
.main_right {
  width: 100%;
  max-width: 450px;

}
.main_right h3{
  margin: 45px 0;
  font-size: var(--fs900);
  text-transform: uppercase;
  font-weight: 400;
}
.contact_info{
  margin-top: 90px;
  min-height: 195px;
}
.info_item{
  min-height: 80px;
  margin: 35px 0;
  
}
.number_title{
  display: block;
  font-size:var(--fs600);
  font-weight: var(--fwMedium);
  border-bottom: 1px solid var(--clrDarkGrey);
  padding-bottom: 5px;
}

.number_phone{
  display: block;
  margin: 13px 0;
  color: var(--clrDarkGrey);
}
.adress_title{
  display: block;
  font-size:var(--fs600);
  font-weight: var(--fwMedium);
  border-bottom: 1px solid var(--clrDarkGrey);
  padding-bottom: 5px;
}
.localisation{
  display: block;
  margin: 13px 0;
  color: var(--clrDarkGrey);
}
input[type="submit"]{
  position: relative;
  background: none;
  width: 100%;
  height: 100%;
  text-align: left;
}
.form_input{
position: relative;
z-index: 1;
width: 50%;
height: 36px;
}
.form_input::after{
    content: '';
    position: absolute;
    display: block;
    background-color: rgba(0,0,0,.2);
    width: 100%;
    z-index: -1;
    height:100%;
    top:0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease;
    
}
.animeSubmit::after{
  transform-origin: left;
  transform: scaleX(1);
}


@media screen and (min-width:1080px) {
  .main_contact {
    margin: 150px 0;
    justify-content: space-between;
    flex-direction: row;
  }
  .contact_info{
  margin-top: 150px;

}
}

</style>
